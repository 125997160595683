.settings-section {
	padding: 20px 0 0;
	margin: 24px 0 0;
	border-top: 1px solid rgba(255, 255, 255, 0.15);

	.settings-section-title {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
}

.settings-user-table {
	display: grid;
	grid-gap: 10px;
	grid-template-columns: 1fr;
	margin-top: 20px;
}

.settings-add-user {
	display: grid;
	grid-gap: 12px;
	grid-template-columns: 1fr;
	margin-top: 12px;

	.settings-add-user__actions {
		display: flex;
		grid-gap: 5px;
	}
}
