.thosp-login-container {
	min-height: 91vh;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 0 20px;
	text-align: center;

	.rbr-logo {
		width: 45%;
		max-width: 175px;
		margin: 0 auto 22px;
	}
}
