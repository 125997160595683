.thosp-container-head {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 14px;

	.thosp-container-user {
		display: flex;
		align-items: center;

		.head-user-picture {
			position: relative;
			height: 32px;
			width: 32px;
			border-radius: 32px;
			margin-right: 10px;
			background: #fff;
			border: 1px solid rgba(255, 255, 255, 0.1);
			overflow: hidden;

			img {
				position: absolute;
				height: 100%;
				width: 100%;
				object-fit: cover;
				object-position: center;
			}
		}
	}

	.thosp-logout {
		cursor: pointer;
	}

	.thosp-container-network {
		.thosp-network-status {
			display: flex;
			align-items: center;
		}

		.network-status-icon {
			display: inline-block;
			height: 9px;
			width: 9px;
			border-radius: 9px;
			margin-left: 3px;

			&.online {
				background: #24a148;
			}

			&.offline {
				background: #d3423d;
			}
		}
	}

	.thosp-offline-toggle {
		position: relative;
		display: inline-block;
		margin-left: 6px;
		margin-bottom: -2px;
		cursor: pointer;
		height: 16px;
		width: 28px;
		border-radius: 14px;
		background: rgba(255, 255, 255, 0.1);

		&:after {
			content: "";
			position: absolute;
			z-index: 3;
			left: 3px;
			top: 3px;
			height: calc(100% - 6px);
			aspect-ratio: 1/1;
			border-radius: 100%;
		}

		&.online {
			&:after {
				left: unset;
				right: 3px;
				background: #24a148;
			}
		}

		&.offline {
			&:after {
				left: 3px;
				right: unset;
				background: #d3423d;
			}
		}
	}
}

.thosp-navigation {
	margin-bottom: 20px;

	.navigation-button {
		&:not(:last-of-type) {
			margin-right: 5px;
		}

		@media (max-width: 480px) {
			margin-bottom: 5px;
		}
	}
}
