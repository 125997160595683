.thosp-guestgroup-row {
	border-radius: 8px;
	padding: 12px 28px 16px;
	box-shadow: rgba(255, 255, 255, 0.05) 0px 6px 24px 0px, rgba(255, 255, 255, 0.08) 0px 0px 0px 1px;

	&.isvcarb{
		background-color: #1434cb;		
	}

	&:first-of-type {
		margin-top: 24px;
	}

	&:not(:last-of-type) {
		margin-bottom: 12px;
	}

	&.previous {
		opacity: 0.65;
	}

	.row-logo{
		height: 80px;
		width: 80px;
		float: left;
		display: flex;

		img{
			max-height: 80px;
			max-width: 80px;
			padding: 0 10px 0px 0px;
			margin: auto;
		}
	}

}

.right {
	float: right;
}

.bottom-20 {
	margin-bottom: 20px;
}

.error {
	--cosmos-text-color: #ff9000;
	margin-top: 20px;
}

.guestgroup-row-code-link {
	cursor: pointer;
	width: fit-content;

	&:hover {
		--cosmos-text-color: #fff;
	}
}

.green {
	color: #24a148;
}

.red {
	color: crimson;
}

