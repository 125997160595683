.thosp-pass-row {
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-radius: 8px;
	padding: 12px 28px 16px;
	box-shadow: rgba(255, 255, 255, 0.05) 0px 6px 24px 0px, rgba(255, 255, 255, 0.08) 0px 0px 0px 1px;

	.thosp-pass-name {
		display: grid;
		grid-gap: 4px;
		align-items: center;
		grid-template-columns: 20px 1fr;
	}

	&.OVERDUE {
		.thosp-pass-timing {
			--cosmos-text-color: #ff9000;
		}
	}

	.thosp-pass-active-icon {
		border-radius: 50%;
		margin: -1px 5px 0 0;
		height: 12px;
		width: 12px;
		transform: scale(1);
		background: rgba(36, 161, 72, 1);
		box-shadow: 0 0 0 0 rgba(36, 161, 72, 1);
		animation: live-pulse 2s infinite;

		@keyframes live-pulse {
			0% {
				transform: scale(0.95);
				box-shadow: 0 0 0 0 rgba(36, 161, 72, 0.7);
			}

			70% {
				transform: scale(1);
				box-shadow: 0 0 0 10px rgba(36, 161, 72, 0);
			}

			100% {
				transform: scale(0.95);
				box-shadow: 0 0 0 0 rgba(36, 161, 72, 0);
			}
		}
	}

	.thosp-pass-warning-icon {
		margin-bottom: -1px;
		margin-right: 5px;
		--cosmos-icon-color: #ff9000;
	}
}
.row-logo{
	height: 80px;
	width: 80px;
	float: left;
	display: flex;

	img{
		max-height: 80px;
		max-width: 80px;
		padding: 0 10px 0px 0px;
		margin: auto;
	}
}