.settings-user-row {
	border-radius: 8px;
	box-shadow: rgba(255, 255, 255, 0.05) 0px 6px 24px 0px, rgba(255, 255, 255, 0.08) 0px 0px 0px 1px;

	.settings-user-row__details {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 12px 28px 16px;

		.settings-user-row__details-actions {
			display: flex;
			grid-gap: 5px;
		}
	}

	.setting-user-row__editing {
		display: grid;
		grid-gap: 10px;
		grid-template-columns: 1fr;
		padding: 16px 28px;
		border-top: 1px solid rgba(255, 255, 255, 0.15);

		.settings-user-row__editing-actions {
			display: flex;
			grid-gap: 5px;
		}
	}

	.is-tour-guide-icon {
		margin-left: 6px;
	}
}
