.thosp-guestgroup-form {
	max-width: 405px;
	margin: auto;

	.thosp-guestgroup-form-header {
		display: flex;
		align-items: center;
		flex-direction: column;

		.rbr-logo {
			width: 45%;
			max-width: 175px;
			margin: 0 auto 22px;
		}

		cosmos-title {
			text-align: center;
		}
	}

	.thosp-guestgroup-form-upload {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		height: 120px;
		border-radius: 4px;
		margin: 20px 0 4px;
		background: rgba(255, 255, 255, 0.05);
		border: 1px solid rgba(255, 255, 255, 0.05);
		cursor: pointer;

		&.is-uploading,
		&.is-processed {
			cursor: default;
			pointer-events: none;
		}

		small {
			display: block;
			font-size: 12px;
			margin: 14px 0;
		}

		span {
			display: inline-block;
			cursor: pointer;
			font-size: 15px;
			font-weight: 500;
			border-bottom: 2px solid hsla(0, 0%, 100%, 0.65);

			&:hover {
				border-bottom: 2px solid rgba(255, 255, 255, 1);
			}
		}
	}

	.thosp-template-download {
		margin: 0 0 15px;

		a {
			font-weight: 600;
			color: #db0a40;
		}
	}

	.thosp-guestgroup-form-submit {
		display: flex;
		align-self: center;
		margin: auto;
	}
}
