.rbr-cosmos-select-wrapper {
	position: relative;

	&.has-error {
		.rbr-cosmos-select-upper {
			border: 1px solid red;
		}

		small {
			color: red;
		}
	}

	&.read-only {
		pointer-events: none;

		.rbr-cosmos-select-upper {
			color: #fff !important;
			background: #fff !important;
			border: 1px solid#fff !important;
		}
	}

	&.only-active-on-hover {
		&:hover {
			.rbr-cosmos-select-upper {
				background: #fff !important;
				border: 1px solid #fff !important;
			}
		}

		.rbr-cosmos-select-upper {
			color: #fff !important;
			background: #fff !important;
			border: 1px solid#fff !important;

			&:hover,
			&:focus,
			&:active {
				background: #fff !important;
				border: 1px solid #fff !important;
			}
		}
	}

	.rbr-cosmos-input-upper-placeholder {
		font-size: 14px;
		font-weight: 500;
		color: #ffffff;
		transition: 0.25s all;
		padding: 0 0 8px 2px;
		pointer-events: none;

		&.has-warning {
			color: rgba(255, 144, 0, 1);
		}
	}

	.rbr-cosmos-input-block {
		input {
			cursor: pointer;
		}
	}

	.rbr-cosmos-select-upper {
		position: relative;
		transition: 0.2s all;
		min-height: 40px;
		cursor: pointer;

		&.dropdown-showing {
			.rbr-cosmos-select-icon {
				svg {
					transform: translateY(-50%) rotate(180deg);
				}
			}
		}

		&.has-value {
			&:not(.no-styles) {
				background: #fff;
				border: 1px solid #fff;

				.rbr-cosmos-select-placeholder {
					font-size: 15px;
					color: #fff;
				}
			}

			.rbr-cosmos-input-wrapper {
				.rbr-cosmos-input-block {
					.rbr-cosmos-input-text {
						border: 1px solid #fff !important;
					}
				}
			}
		}

		.rbr-cosmos-input-wrapper {
			.rbr-cosmos-input-block {
				.rbr-cosmos-input-text {
					&:focus,
					&:active,
					&:not(:placeholder-shown) {
						border: 1px solid#fff;
					}
				}
			}
		}

		.rbr-cosmos-input-text {
			&.is-empty {
				border: 1px solid rgba(255, 255, 255, 0.65) !important;

				&:hover {
					border: 1px solid rgba(255, 255, 255, 1) !important;
				}
			}
		}

		.rbr-cosmos-nonsearch-select-wrap {
			position: relative;
			background: rgba(255, 255, 255, 0.05);
			border: 1px solid rgba(255, 255, 255, 0.5);
			border-radius: 4px;
			color: #fff;
			font-family: 'Bull Text', Helvetica, sans-serif;
			font-size: 16px;
			height: 40px;
			outline: none !important;
			text-indent: 18px;
			padding: 0 2px;

			.rbr-cosmos-select-icon {
				top: calc(50% + 1px);

				svg {
					left: 0;
				}
			}
		}

		.rbr-cosmos-select-placeholder {
			position: relative;
			font-size: 16px;
			font-weight: 400;
			color: #ffffff;
			font-family: 'Bull Text', Helvetica, sans-serif;
			min-height: 40px;
			line-height: 40px;
			margin: 0;
			display: flex;
			align-items: center;
		}

		.rbr-cosmos-select-icon {
			position: absolute;
			top: calc(50% + 10px);
			right: 16px;
			transform: translateY(-50%);

			svg {
				position: absolute;
				top: 50%;
				height: 10px;
				transform: translateY(-50%);
				transition: 0.2s all;
			}
		}
	}

	.rbr-cosmos-select-dropdown {
		position: absolute;
		z-index: 1005;
		border-radius: 4px;
		background: #000f1e;
		max-height: 0;
		overflow: hidden;
		top: calc(100% + 5px);
		width: 100%;

		&:after {
			content: '';
			position: absolute;
			z-index: 1;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			pointer-events: none;
			background: rgba(255, 255, 255, 0.04);
		}

		&.is-showing {
			max-height: 355px;
			overflow-y: auto;
			border: 1px solid rgba(255, 255, 255, 0.5);
			box-shadow: 0px 4px 25px 6px rgba(0, 0, 0, 0.5);
		}

		.rbr-cosmos-multiple-select-option {
			position: relative;
			z-index: 2;
			font-size: 14px;
			font-weight: 400;
			padding: 8px 18px;

			.rbr-cosmos-checkbox {
				width: 100%;
			}
		}

		.rbr-cosmos-select-option {
			cursor: pointer;
			padding: 0 18px;
			height: 40px;
			display: flex;
			align-items: center;

			&.is-active,
			&:hover {
				background: rgba(255, 255, 255, 0.05);
			}

			&:not(:last-of-type) {
				border-bottom: 1px solid rgba(255, 255, 255, 0.5);
			}

			p {
				font-size: 16px;
				line-height: 40px;
				font-weight: 400;
				color: #fff;
			}

			&.selected-are-disabled {
				&.is-selected {
					cursor: default;
					color: #8d8f94 !important;
					background: #fff !important;

					&.is-active,
					&:hover {
						color: #8d8f94 !important;
						background: #fff !important;
					}
				}
			}
		}
	}

	small {
		display: block;
		font-size: 12px;
		font-weight: 400;
		line-height: 1em;
		margin: 5px 0 0;
		color: #000;
	}
}
