@import "@cosmos/foundation/lib/primitives/font/default.css";

html,
body {
	margin: 0;
}

body {
	&.scope_ORBR {
		color: #fff;
		background: #000f1e;
	}

	&.scope_VCARB {
		color: #000;
		background: #fff;
	}
}

* {
	box-sizing: border-box;
}

#app_screens {
	position: relative;
	max-width: 590px;
	margin: 4.5vh auto;
	padding: 0 12px;

	@media (max-width: 480px) {
		margin: 2vh auto;
	}
}

.thosp-header {
	padding: 0 0 20px;
	margin: 0 0 24px;
	border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}

.thosp-tab-navigation {
	padding: 5px 0 0;

	&:not(.slimmed) {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	@media (max-width: 480px) {
		padding: 5px 0;
	}

	.navigation-button {
		&:not(:last-of-type) {
			margin-right: 14px;

			@media (max-width: 480px) {
				margin-right: 12px;
			}
		}
	}
}

.thosp-event-date {
	display: flex;
	align-items: center;

	&.no-wrap {
		white-space: nowrap;
	}
}

.thosp-event-location {
	display: flex;
	align-items: center;
	margin: 6px 0 10px;

	cosmos-flag {
		height: 14px;
		margin-top: -1px;
		margin-right: 5px;
	}
}

.thosp-guest-row {
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-radius: 8px;
	padding: 12px 28px 16px;
	box-shadow: rgba(255, 255, 255, 0.05) 0px 6px 24px 0px, rgba(255, 255, 255, 0.08) 0px 0px 0px 1px;

	@media (max-width: 480px) {
		align-items: flex-start;
		flex-direction: column;
	}

	&:first-of-type {
		margin-top: 24px;
	}

	&:not(:last-of-type) {
		margin-bottom: 12px;
	}

	&.is-loading {
		opacity: 0.65;
	}

	&.checked-in {
		opacity: 0.4;
	}

	&.check-in-available {
		justify-content: unset;
	}

	.thosp-loading-overlay {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
	}

	.guest-row-checkin {
		margin-left: auto;
		--cosmos-checkbox-text-font-size: 10px;

		@media (max-width: 480px) {
			margin-left: 0;
			margin-top: 5px;
		}
	}

	.guest-row-delete {
		margin-left: 15px;

		@media (max-width: 480px) {
			margin-left: 0;
			margin-top: 5px;
		}
	}
}

.thosp-checkin-count {
	&.is-full {
		--cosmos-text-color: #24a148;
	}

	&.is-not-full {
		--cosmos-text-color: #ff9000;
	}
}

.thosp-viewing-checkin-pin {
	position: fixed;
	z-index: 999;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	background-color: #000f1e;
	padding: 0 20px;
	text-align: center;

	.rbr-logo {
		width: 45%;
		max-width: 175px;
		margin: 0 auto 22px;
	}

	.vcarb-logo {		
		max-width: 175px;
		margin: 0 auto 22px;
	}

	.thosp-pin {
		--cosmos-title-font-size: 8.5rem;
	}
}

.button-disperse {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 14px 0 0;
}

.thosp-message-green {
	--cosmos-text-color: #24a148;
}

.thosp-message-orange {
	--cosmos-text-color: #ff9000;
}