.thosp-tour-guest-row {
    position: relative;
	display: flex;
	align-items: center;
	border-radius: 8px;
	padding: 12px 28px 16px;
	box-shadow: rgba(255, 255, 255, 0.05) 0px 6px 24px 0px, rgba(255, 255, 255, 0.08) 0px 0px 0px 1px;

	&:not(:last-of-type) {
		margin-bottom: 12px;
	}

    cosmos-text {
        margin-right: auto;
    }

    cosmos-button {
        margin-left: 5px;
    }

    .thosp-tour-guest-swap-modal {
        position: absolute;
        z-index: 100;
        top: 0;
        left: 5px;
        right: 5px;
        bottom: 0;
        padding: 0 7.5px;
        display: flex;
        align-items: center;
        justify-content: center;
        backdrop-filter: blur(10px);
        background-color: rgba(0, 15, 30, 0.65);
        opacity: 0;
        pointer-events: none;

        &.is-toggled {
            opacity: 1;
            pointer-events: all;
        }
    }
}

.thosp-tour-heading {
    margin-bottom: 12px;

    .is-started-flag {
		--cosmos-text-color: #24a148;
	}

	.is-upcoming-flag {
		--cosmos-text-color: #8a3ffc;
	}
}

.thosp-tour-guest-count {
    margin-bottom: 8px;
}

.thosp-name-select {	
	position: absolute;
	list-style-type: none;
	margin: -25px 0 0 0;
	padding: 0;	
	top: calc(100% + 5px);
	width: calc(100% - 50px);
	background: #000f1e;
	border: 1px solid rgba(255, 255, 255, 0.5);
	box-shadow: 0px 4px 25px 6px rgba(0, 0, 0, 0.5);
	border-radius: 4px;
	z-index: 10;

	&:after {
		content: '';
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		pointer-events: none;
		background: rgba(255, 255, 255, 0.04);
	}
	
	li {
		cursor: pointer;
		padding: 0 18px;
		height: 40px;
		display: flex;
		align-items: center;
		--cosmos-text-color: rgb(255, 255, 255);

		&:hover {
			&:not(.name-select-option-empty) {
				background: rgba(255, 255, 255, 0.05);
			}
		}

		&:not(:last-of-type) {
			border-bottom: 1px solid rgba(255, 255, 255, 0.5);	
		}

		&.name-select-option-empty {
			cursor: default;
			opacity: 0.65;
		}
	}
}