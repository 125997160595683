.thosp-event-row {
	border-radius: 8px;
	padding: 12px 28px 16px;
	transition: 0.2s;
	box-shadow: rgba(255, 255, 255, 0.05) 0px 6px 24px 0px, rgba(255, 255, 255, 0.08) 0px 0px 0px 1px;

	&:first-of-type {
		margin-top: 24px;
	}

	&:not(:last-of-type) {
		margin-bottom: 12px;
	}

	&:hover {
		&.completed {
			opacity: 1;
		}
	}

	&.completed,
	&.previous {
		opacity: 0.65;
	}
}

.thosp-tour-row {
	transition: 0.2s;

	&:hover {
		&.started {
			opacity: 1;
		}
	}

	&.started {
		opacity: 0.65;
	}
}

.thosp-event-name {
	display: flex;
	align-items: flex-end;

	.thosp-event-settings {
		margin-bottom: -1.5px;
		margin-left: 14px;
	}
}

.pin-redemption-result {
	&.is-success {
		.result-text {
			--cosmos-text-color: #24a148;
		}
	}

	&.is-error {
		.result-text {
			--cosmos-text-color: #ff9000;
		}
	}
}

.thosp-guest-list-filtering {
	display: grid;
	grid-gap: 10px;
	grid-template-columns: 1fr 1fr auto auto;
	align-items: flex-end;

	cosmos-button {
		margin-bottom: 2px;
	}

	@media (max-width: 480px) {
		grid-template-columns: 1fr auto;

		cosmos-input {
			&:first-of-type {
				grid-column-end: span 2;
			}
		}
	}
}

.thosp-header-button {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.thosp-add-guest-form {
	margin: 20px 0 0;
	display: grid;
	grid-gap: 10px;
	grid-template-columns: 1fr 1fr;

	@media (max-width: 480px) {
		grid-template-columns: 1fr;
	}

	.span-2 {
		grid-column-end: span 2;

		@media (max-width: 480px) {
			grid-column-end: span 1;
		}
	}

	.add-tour-buttons,
	.add-guest-buttons {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.check-guest-in {
		margin: 2px 0 12px;
	}
}

.thosp-add-tour-form {
	margin: 20px 0 0;
	display: grid;
	grid-gap: 10px;
	grid-template-columns: 1fr 1fr;

	@media (max-width: 480px) {
		grid-template-columns: 1fr;
	}

	.span-2 {
		grid-column-end: span 2;

		@media (max-width: 480px) {
			grid-column-end: span 1;
		}
	}

	.add-tour-buttons {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-top: 12px;
	}
}

.thosp-passes-input-row {
	display: grid;
	grid-gap: 10px;
	grid-template-columns: 1fr 1fr auto;

	.thosp-passes-name-input {
		position: relative;

		.thosp-passes-name-field {
			position: relative;
			z-index: 2;
		}

		.thosp-passes-dropdown-wrapper {
			position: absolute;
			z-index: 1;
			top: calc(100% + 10px);
			left: 0;
			right: 0;
			bottom: 0;
			border: 1px solid rgba(255, 255, 255, 0.45);
			background-color: rgb(0, 22, 43);
			border-radius: 4px;
			padding: 4px;
			height: fit-content;
			max-height: 320px;
			opacity: 0;
			pointer-events: none;

			&.is-toggled {
				opacity: 1;
				pointer-events: all;
			}
		}
	}

	cosmos-button {
		margin-top: 27px;
	}
}

.all-radios-allocated {
	--cosmos-text-color: #ff9000;
}

.thosp-select-dropdown {
	background-color: var(--cosmos-select-background-color, rgba(255, 255, 255, 0.05));
	border-color: var(--cosmos-select-border-color, rgba(255, 255, 255, 0.45));
	color: var(--cosmos-select-color, #ffffff);
}

.thosp-redemption-pin {
	display: grid;
	grid-gap: 10px;
	grid-template-columns: 1fr auto;

	cosmos-button {
		margin-top: 27px;
	}

	&.is-via-name {
		position: relative;
		z-index: 10;
	}
}

.thosp-group-redemption {
	margin-top: 14px;
}

.thosp-name-select {
	position: absolute;
	list-style-type: none;
	margin: 5px 0 0 0;
	padding: 0;
	top: calc(100% + 5px);
	width: calc(100% - 50px);
	background: #000f1e;
	border: 1px solid rgba(255, 255, 255, 0.5);
	box-shadow: 0px 4px 25px 6px rgba(0, 0, 0, 0.5);
	border-radius: 4px;
	z-index: 10;

	&:after {
		content: "";
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		pointer-events: none;
		background: rgba(255, 255, 255, 0.04);
	}

	li {
		cursor: pointer;
		padding: 0 18px;
		height: 40px;
		display: flex;
		align-items: center;
		--cosmos-text-color: rgb(255, 255, 255);

		&:hover {
			&:not(.name-select-option-empty) {
				background: rgba(255, 255, 255, 0.05);
			}
		}

		&:not(:last-of-type) {
			border-bottom: 1px solid rgba(255, 255, 255, 0.5);
		}

		&.name-select-option-empty {
			cursor: default;
			opacity: 0.65;
		}
	}
}

.thosp-name-select.hide {
	display: none;
}

.thosp-group-checkin-filtering {
	display: grid;
	grid-gap: 10px;
	grid-template-columns: 1fr 1fr auto auto;
	align-items: flex-end;
	margin-bottom: 10px;

	cosmos-button {
		margin-right: -10px;
	}

	@media (max-width: 480px) {
		grid-template-columns: 1fr auto;
	}
}

.thosp-tour-buttons {
	display: flex;
	justify-content: space-between;
	margin-top: 12px;
}

.thosp-passes-grid {
	display: grid;
	grid-gap: 12px;
}

.thosp-tour-status-row {
	.is-upcoming-flag {
		--cosmos-text-color: #8a3ffc;
	}

	.is-started-flag {
		--cosmos-text-color: #24a148;
	}
}

.thosp-tour-checkin-select {
	width: 95%;
	max-width: 355px;
	text-align: left;
}

.thosp_predictor-submissions-table {
	width: 100%;

	tr {
		th {
			text-align: left;
		}

		td {
			white-space: nowrap;

			&:nth-child(2) {
				position: relative;
				max-width: 70%;
				overflow: hidden;
			}
		}
	}
}

.redemption-remaining-title {
	margin: 12px 0;
}

.redemption-stat-holder {
	align-items: center;
	border-radius: 8px;
	box-shadow: 0 6px 24px 0 hsla(0, 0%, 100%, 0.05), 0 0 0 1px hsla(0, 0%, 100%, 0.08);
	display: flex;
	justify-content: space-between;
	padding: 10px 28px;

	&:not(:last-of-type) {
		margin-bottom: 10px;
	}

	.redemption-stat {
		display: block;
		padding: 4px 8px;
		background-color: #1a2735;
		border-radius: 4px;
		font-size: 12px;
	}
}

.row-logo {
	height: 80px;
	width: 80px;
	float: left;
	display: flex;

	img {
		max-height: 80px;
		max-width: 80px;
		padding: 0 10px 0px 0px;
		margin: auto;
	}
}

.warningIcon {
	margin-right: 5px;
	color: #e06600;
	--cosmos-icon-color: #e06600;
	--cosmos-text-color: #e06600;
}

.thosp-redemption-modal-container {
	position: fixed;
	z-index: -1;
	display: flex;
	align-items: center;
	justify-content: center;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(0, 15, 30, 0.65);
	opacity: 0;
	transition: 300ms all;
	pointer-events: none;

	&.is-active {
		z-index: 23051999;
		opacity: 1;
		pointer-events: all;
	}

	.thosp-close-modal-backdrop {
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}

	.thosp-redemption-modal {
		position: relative;
		z-index: 2;
		display: block;
		background: #000f1e;
		border: 1px solid rgba(255, 255, 255, 0.1);
		min-height: 500px;
		width: calc(100% - 40px);
		max-width: 600px;
		box-shadow: 0 4px 25px 6px rgba(255, 255, 255, 0.05);
		border-radius: 12px;
		padding: 20px 24px;

		.thosp-modal-close {
			position: absolute;
			top: 20px;
			right: 20px;
		}

		.thosp-modal-intro {
			margin-top: 5px;
			margin-bottom: 20px;
		}

		.thosp-redemption-types {
			.thosp-redemption-type {
				border: 1px solid rgba(255, 255, 255, 0.1);
				background: rgba(255, 255, 255, 0.05);
				border-radius: 6px;
				padding: 6px 12px;
				cursor: pointer;
				user-select: none;

				&.is-disabled {
					opacity: 0.45;
					cursor: default;
					pointer-events: none;
				}

				&:hover {
					&:not(.is-disabled) {
						background: rgba(255, 255, 255, 0.1);
					}
				}

				&:not(:last-of-type) {
					margin-bottom: 12px;
				}
			}
		}
	}
}
