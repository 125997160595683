.thosp-event-status {
	display: flex;
	align-items: center;
	margin-left: 8px;
	margin-bottom: -1.5px;
	--cosmos-text-color: #fff;

	.event-date-icon {
		margin-right: 3px;
	}

	&.previous {
		--cosmos-text-color: #8d8d8d;
	}

	&.live {
		--cosmos-text-color: #24a148;
	}

	&.upcoming {
		--cosmos-text-color: #8a3ffc;
	}

	.event-live {
		border-radius: 50%;
		margin: -1px 7px 0 7px;
		height: 12px;
		width: 12px;
		transform: scale(1);
		background: rgba(36, 161, 72, 1);
		box-shadow: 0 0 0 0 rgba(36, 161, 72, 1);
		animation: live-pulse 2s infinite;
	}
}

@keyframes live-pulse {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(36, 161, 72, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(36, 161, 72, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(36, 161, 72, 0);
	}
}
